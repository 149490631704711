<template>
  <div class="card" data-aos="fade-right" data-aos-delay="100" v-if="false">
    <div class="card-body text-center">
      <h5 class="card-title mb-4">Привязанные аккаунты</h5>
      <div class="d-flex flex-wrap gap-2 justify-content-center">
        <div class="avatar-xs">
            <span
                class="avatar-title rounded-circle fs-16 bg-dark text-light"
            >
              <i class="bx bxl-vk"></i>
            </span>
        </div>
        <div class="avatar-xs">
            <span
                class="avatar-title rounded-circle fs-16 bg-primary"
            >
              <i class="ri-discord-fill"></i>
            </span>
        </div>
        <div class="avatar-xs">
            <span
                class="avatar-title rounded-circle fs-16 bg-success"
            >
              <i class="ri-telegram-fill"></i>
            </span>
        </div>
      </div>
    </div>
    <!-- end card body -->
  </div>
  <!-- end card -->
</template>

<script>
export default {
  name: "SocialView",
}
</script>

<style scoped>

</style>