<template>
  <div>
    <div class="row" style="min-height: 80px">
      <p v-if="logs?.data?.length < 1" class="text-center mt-2">Тут пусто...</p>
      <div
          v-else
          class="d-flex align-items-center mb-4 col-lg-6"
          v-for="(item, index) in logs.data"
          :key="index"
      >
        <div class="flex-shrink-0 avatar-sm">
          <div
              class="avatar-title bg-light text-primary rounded-3 fs-18"
          >
            <i v-if="item.device === 0" class="ri-computer-line"></i>
            <i v-else-if="item.device === 1" class="ri-phone-line"></i>
            <i v-else class="ri-global-line"></i>
          </div>
        </div>
        <div class="flex-grow-1 ms-3">
          <h6>{{ item.browser }}</h6>
          <p class="text-muted mb-0">
            Вход {{ formatDate(item.created_at) || 'Неизвестно' }} | ip: {{ item.ip_address || 'Неизвестно' }}
            | Локация: {{ item.location || 'Неизвестно' }}
          </p>
        </div>
      </div>
    </div>

    <div v-if="logs?.data?.length > 0" class="d-flex justify-content-end p-3">
      <div class="pagination-wrap hstack gap-2">
        <button
            class="page-item pagination-prev"
            :disabled="logs.current_page === 1"
            @click="$emit('reload', logs.current_page - 1)"
        >
          <i class="ri ri-arrow-left-s-line"></i>
        </button>
        <ul class="pagination listjs-pagination mb-0">
          <li
              :class="{
                        active: pageNumber === logs.current_page,
                      }"
              v-for="(pageNumber, index) in logs.pages.slice(
                          Math.max(Math.min(logs.current_page - 3, logs.last_page - 5), 0),
                          Math.max(logs.current_page + 2, 5),
                      )"
              :key="index"
              @click="$emit('reload', pageNumber)"
          >
            <a class="page" role="button">{{ pageNumber }}</a>
          </li>
        </ul>
        <button
            class="page-item pagination-next"
            @click="$emit('reload', logs.current_page + 1)"
            :disabled="logs.current_page >= logs.last_page"
        >
          <i class="ri ri-arrow-right-s-line"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { formatDate } from "@/helpers/timedate";

export default {
  name: "profile-logs-login",
  props: ['logs'],
  methods: { formatDate },
}
</script>

<style scoped>

</style>