<template>
  <div>
    <div class="row" style="min-height: 80px">
      <p v-if="sessions?.data?.length < 1" class="text-center mt-2">Тут пусто...</p>
      <div
          v-else-if="sessions"
          class="d-flex align-items-center mb-4 col-lg-6"
          v-for="(item, index) in sessions.data"
          :key="index"
      >
        <div class="flex-shrink-0 avatar-sm">
          <div
              class="avatar-title bg-light text-primary rounded-3 fs-18"
          >
            <i v-if="item.name === 'launcher'" class="ri-computer-line"></i>
            <i v-else class="ri-global-line"></i>
          </div>
        </div>
        <div class="flex-grow-1 ms-3">
          <p class="text-muted mb-0">
            Вход: {{ formatDate(item.created_at) || 'Неизвестно' }}
            | Последняя активность: {{ formatDate(item.last_used_at) || 'Неизвестно' }}
            | ip: {{ item.ip_address || 'Неизвестно' }}
            | Локация: {{ item.location || 'Неизвестно' }}
          </p>
        </div>
        <button @click="$emit('delete', item.id); $emit('reload', pageNumber)" class="btn btn-soft-danger">Удалить</button>
      </div>
    </div>

    <div v-if="sessions?.data?.length > 0" class="d-flex justify-content-end p-3">
      <div class="pagination-wrap hstack gap-2">
        <button
            class="page-item pagination-prev"
            :disabled="sessions.current_page === 1"
            @click="$emit('reload', sessions.current_page - 1)"
        >
          <i class="ri ri-arrow-left-s-line"></i>
        </button>
        <ul class="pagination listjs-pagination mb-0">
          <li
              :class="{
                        active: pageNumber === sessions.current_page,
                      }"
              v-for="(pageNumber, index) in sessions.pages.slice(
                          Math.max(Math.min(sessions.current_page - 3, sessions.last_page - 5), 0),
                          Math.max(sessions.current_page + 2, 5),
                      )"
              :key="index"
              @click="$emit('reload', pageNumber)"
          >
            <a class="page" role="button">{{ pageNumber }}</a>
          </li>
        </ul>
        <button
            class="page-item pagination-next"
            @click="$emit('reload', sessions.current_page + 1)"
            :disabled="sessions.current_page >= sessions.last_page"
        >
          <i class="ri ri-arrow-right-s-line"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { formatDate } from "@/helpers/timedate";

export default {
  name: "profile-sessions-login",
  props: ['sessions'],
  methods: {
    formatDate,
  },
}
</script>

<style scoped>

</style>