<template>
  <div class="profile-foreground position-relative mx-n4 mt-n4">
    <div class="profile-wid-bg" style="height: 260px">
      <img
          :src="banner"
          alt=""
          class="profile-wid-img"
      />
    </div>
  </div>
</template>

<script>

export default {
  name: "profile-banner",
  props: ['banner'],
}
</script>

<style scoped>

</style>