<template>
  <div class="card" data-aos="fade-right">
    <div class="card-body">
      <h5 class="card-title mb-3">Информация</h5>
      <div class="table-responsive">
        <table class="table table-borderless mb-0">
          <tbody>
          <tr>
            <th class="ps-0" scope="row">Ник:</th>
            <td class="text-muted">{{ user.login }}</td>
          </tr>
          <tr>
            <th class="ps-0" scope="row">Имя:</th>
            <td class="text-muted">{{ user.name || 'Неизвестно...' }}</td>
          </tr>
          <tr>
            <th class="ps-0" scope="row">Роль:</th>
            <td class="text-muted">{{ user.role_name }}</td>
          </tr>
          <tr v-if="user.email">
            <th class="ps-0" scope="row">E-mail:</th>
            <td class="text-muted d-flex align-items-center">
              {{ user.email }}
              <i v-if="user.email_verified_at" class="p-0 ms-1 text-success ri-mail-check-fill"></i>
              <i v-else class="p-0 ms-1 text-danger ri-mail-forbid-fill"></i>
            </td>
          </tr>
          <tr>
            <th class="ps-0" scope="row">День рождения:</th>
            <td class="text-muted">
              {{ user.birthday ? formatDate(user.birthday) : 'Самый замечательный!' }}
            </td>
          </tr>
          <tr>
            <th class="ps-0" scope="row">Дата регистрации:</th>
            <td class="text-muted">
              {{ formatDate(user.created_at) }}
            </td>
          </tr>
          <tr>
            <th class="ps-0" scope="row">Последняя активность:</th>
            <td class="text-muted">
              {{ formatDate(user.updated_at) }}
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- end card body -->
  </div>
  <!-- end card -->
</template>

<script>
import { formatDate } from "@/helpers/timedate";

export default {
  name: "profile-user-info",
  props: ['user'],
  methods: { formatDate }
}
</script>

<style scoped>

</style>