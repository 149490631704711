<template>
  <div class="card" data-aos="fade-left" data-aos-delay="100">
    <div class="card-body">
      <h5 class="card-title mb-3">Обо мне</h5>
      <p style="white-space: pre">
        {{ user.about || 'тут пусто...' }}
      </p>
      <div class="row">
        <div class="col-12 col-sm-6 col-md-4">
          <div class="d-flex mt-4">
            <div
                class="flex-shrink-0 avatar-xs align-self-center me-3"
            >
              <div
                  class="avatar-title bg-light rounded-circle fs-16 text-primary"
              >
                <i class="ri-user-2-fill"></i>
              </div>
            </div>
            <div class="flex-grow-1 overflow-hidden">
              <p class="mb-1">Статус:</p>
              <h6 class="text-truncate mb-0">
                {{ user.status || '-' }}
              </h6>
            </div>
          </div>
        </div>
        <!--end col-->
        <div class="col-12 col-sm-6 col-md-4">
          <div class="d-flex mt-4">
            <div
                class="flex-shrink-0 avatar-xs align-self-center me-3"
            >
              <div
                  class="avatar-title bg-light rounded-circle fs-16 text-primary"
              >
                <i class="ri-global-line"></i>
              </div>
            </div>
            <div class="flex-grow-1 overflow-hidden">
              <p class="mb-1">Web-сайт:</p>
              <a v-if="user.website" :href="'https://' + user.website" target="_blank" class="fw-semibold">
                {{ user.website }}
              </a>
              <router-link v-else :to="'/users/' + user.login" class="fw-semibold">
                {{ 'finaldream.net/user/' + user.login }}
              </router-link>
            </div>
          </div>
        </div>

        <div class="col-12 col-sm-6 col-md-4">
          <div class="d-flex mt-4">
            <div
                class="flex-shrink-0 avatar-xs align-self-center me-3"
            >
              <div
                  class="avatar-title bg-light rounded-circle fs-16 text-primary"
              >
                <i v-if="user.gender === 0" class="ri-men-line"></i>
                <i v-else-if="user.gender === 1" class="ri-women-line"></i>
                <i v-else class="ri-travesti-line"></i>
              </div>
            </div>
            <div class="flex-grow-1 overflow-hidden">
              <p class="mb-1">Гендер:</p>
              <h6 class="text-truncate mb-0">
                <span v-if="user.gender === 0">Мужской</span>
                <span v-else-if="user.gender === 1">Женский</span>
                <span v-else>Неизвестно</span>
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "profile-about",
  props: ['user']
}
</script>

<style scoped>

</style>