<template>
  <div class="row g-4 mb-3">
    <div class="col-auto">
      <img
          :src="user.avatar"
          alt="user-img"
          class="
                  rounded-circle
                  avatar-xl
                  img-thumbnail
                  user-profile-image
                "
      />
    </div>
    <!--end col-->
    <div class="col mb-2 d-flex align-items-center">
      <div class="p-2">
        <h3 class="text-white mb-1">{{ user.login }}</h3>
        <p class="text-white-75 mb-2">{{ user.role_name }}</p>
        <div class="hstack text-white-50">
          <div class="me-2" v-if="user.balance >= 0">
            <i class="ri-copper-coin-line me-1 text-white-75 fs-16 align-middle"></i>
            {{ user.balance }} монет
          </div>
        </div>
      </div>
    </div>
    <!--end col-->
    <slot />
  </div>
  <!--end row-->
</template>

<script>
export default {
  name: "profile-banner-inner",
  props: ['user']
}
</script>

<style scoped>

</style>